@font-face {
  font-family: 'Roboto-medium';
  src: url('/assets/fonts/roboto/Roboto-Medium.eot');
  src: url('/assets/fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/roboto/Roboto-Medium.woff') format('woff'), url('/assets/fonts/roboto/Roboto-Medium.ttf') format('truetype'),
    url('/assets/fonts/roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('/assets/fonts/roboto/Roboto-Bold.eot');
  src: url('/assets/fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'), url('/assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
    url('/assets/fonts/roboto/Roboto-Bold.ttf') format('truetype'), url('/assets/fonts/roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-regular';
  src: url('/assets/fonts/roboto/Roboto-Regular.eot');
  src: url('/assets/fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'), url('/assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
    url('/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype'),
    url('/assets/fonts/roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-flex';
  src: url('/assets/fonts/RobotoFlex.ttf') format('truetype');
  font-weight: 100 1000;
}
