@import "../variables.scss";

.svc-flex-container {
  display: flex;
}

.svc-flex-row {
  display: flex;
  flex-direction: row;
  background: var(--ctr-surface-background-color, $background-dim);
}

.svc-full-container {
  height: 100%;
  width: 100%;
}

.svc-flex-row.svc-full-container {
  height: calcSize(60);
  min-height: calcSize(60);
  flex-grow: 1;
  position: relative;
}

.svc-flex-row__element--growing {
  flex: 1;
  overflow: auto;
}

.svc-flex-column {
  display: flex;
  flex-direction: column;
}

.sv-action-bar--default-size-mode .sv-action-bar-separator {
  height: calcSize(5);
}
.sv-action-bar--default-size-mode .sv-action-bar-item {
  height: auto;
  width: auto;
}

.sv-action-bar--small-size-mode .sv-action-bar-separator {
  height: calcSize(4);
}
.sv-action-bar--small-size-mode .sv-action-bar-item {
  height: auto;
  width: auto;
}

.svc-creator--mobile {
  .sv-action-bar-separator {
    height: calcSize(3);
  }
}

.sv-action-bar--small-size-mode .sv-action-bar-item {
  @include ctrSmallBoldFont;
  align-items: center;
  border-radius: var(--ctr-survey-question-panel-toolbar-item-corner-radius, 2px);
}

.svc-top-bar {
  display: flex;
  background: var(--ctr-menu-item-background-color, $background);
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px var(--ctr-menu-border-color, $border);

  .svc-toolbar-wrapper {
    flex: 0 0 auto;
    display: flex;

    .sv-action-bar {
      padding: var(--ctr-menu-toolbar-padding-vertical, 0) var(--ctr-menu-toolbar-padding-horizontal, calcSize(1));
      justify-content: flex-end;
    }

    .sv-action-bar-separator {
      display: none;
    }

    .sv-action-bar > .sv-action:not(.sv-action--hidden) ~ .sv-action .sv-action-bar-separator {
      --ctr-menu-toolbar-button-gap: calc(var(--ctr-menu-toolbar-gap) / 2);
      --ctr-menu-toolbar-button-height: calc(
        var(--ctr-menu-toolbar-button-padding-top) + var(--ctr-menu-toolbar-button-padding-bottom) +
          var(--ctr-menu-toolbar-button-icon-width)
      );

      display: inline-block;
      margin: 0 var(--ctr-menu-toolbar-button-gap, calcSize(1));
      height: var(--ctr-menu-toolbar-button-height, calcSize(5));
    }

    .sv-action .sv-action-bar-item {
      --ctr-menu-toolbar-button-gap: calc(var(--ctr-menu-toolbar-gap) / 2);
      margin: 0 var(--ctr-menu-toolbar-button-gap, calcSize(1));
      padding: var(--ctr-menu-toolbar-button-padding-top, calcSize(1))
        var(--ctr-menu-toolbar-button-padding-right, calcSize(1))
        var(--ctr-menu-toolbar-button-padding-bottom, calcSize(1))
        var(--ctr-menu-toolbar-button-padding-left, calcSize(1));
    }
  }

  .sv-action-bar-separator {
    background-color: var(--ctr-separator-color, $border);
  }

  .sv-action-bar-item {
    @include ctrDefaultFont;
    border-radius: var(--ctr-menu-toolbar-button-corner-radius, 2px);
    background-color: transparent;
    color: var(--ctr-menu-toolbar-button-text-color, $foreground);
    transition: background-color $creator-transition-duration;
    height: auto;
  }

  .sv-action-bar-item--icon {
    padding: var(--ctr-menu-toolbar-button-padding-top, calcSize(1))
      var(--ctr-menu-toolbar-button-padding-right, calcSize(1))
      var(--ctr-menu-toolbar-button-padding-bottom, calcSize(1))
      var(--ctr-menu-toolbar-button-padding-left, calcSize(1));
    border-radius: var(--ctr-menu-toolbar-button-corner-radius, 0);
  }

  .sv-action-bar-item__icon {
    width: var(--ctr-menu-toolbar-button-icon-width, calcSize(3));
    height: var(--ctr-menu-toolbar-button-icon-height, calcSize(3));

    use {
      fill: var(--ctr-menu-toolbar-button-icon-color, $foreground-light);
    }
  }

  //hovered state
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):hover:enabled,
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):focus:enabled {
    background-color: var(--ctr-menu-toolbar-button-background-color-hovered, $background-dim);
  }

  //pressed state
  .sv-action-bar-item:not(.sv-action-bar-item--pressed):active:enabled {
    opacity: var(--ctr-menu-toolbar-button-opacity-pressed, 0.5);
  }

  //disabled state
  .sv-action-bar-item:disabled {
    opacity: var(--ctr-menu-toolbar-button-opacity-disabled, 0.25);
  }


  .sv-action-bar-item--active {
    .sv-action-bar-item__icon use {
      fill: var(--ctr-menu-toolbar-button-text-color-selected, $primary);
    }
  }

  .sv-action-bar-item-dropdown {
    border-radius: calcCornerRadius(0.5);
    background-color: transparent;
  }

  .sv-action-bar-item--pressed:not(.sv-action-bar-item--active) {
    background-color: var(--ctr-menu-toolbar-button-background-color-pressed, $background-dim);
    opacity: var(--ctr-menu-toolbar-button-opacity-pressed, 50%);
  }
}

.svc-footer-bar {
  .svc-toolbar-wrapper {
    height: calcSize(6);

    .sv-action-bar {
      justify-content: center;
      padding: 0;
      width: 100%;

      height: calc(6 * #{$base-unit});
      background: $background;
      border-top: 1px solid $border;
      box-sizing: border-box;
    }

    .sv-action-bar-item__icon {
      width: var(--ctr-menu-toolbar-button-icon-width, calcSize(3));
      height: var(--ctr-menu-toolbar-button-icon-height, calcSize(3));
    }
  }
}