/***************************
AG Grid styles
****************************/

@import 'abstracts/variables-override';
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'node_modules/survey-core/defaultV2.min.css';
@import 'node_modules/quill/dist/quill.core.css';
@import 'node_modules/quill/dist/quill.snow.css';

// Override Vendors variables
@import 'vendors/bootstrap-variables';
@import 'bootstrap/scss/bootstrap';
@import 'vendors/bootstrap-overrides';
@import 'vendors/surveyJs';
@import 'vendors/quill';

@import 'base/base';
@import 'base/icons';
@import 'base/oc-icons';
@import 'base/reset';
@import 'base/typography';

@import 'layout/fonts';
@import 'layout/forms';

@import 'components/buttons';
@import 'components/modal';
@import 'components/search-bar';
@import 'components/table';
@import 'components/dropdown';
@import 'components/custom-checkbox';
@import 'components/dataset-grid';
@import 'components/register';
@import 'components/campaign-request';
@import 'components/slider';
@import 'components/fade-text';

@import 'pages/login';
@import 'pages/dataset-detail';
@import 'pages/subscriptions';
@import 'pages/consent';
@import 'pages/landings';
