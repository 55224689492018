/* login reset styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body,
p,
ul {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

button {
  &:focus {
    outline: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  margin-top: 0;
}
