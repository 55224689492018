.ql-snow {
  .ql-picker {
    &.ql-size {
      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}
.ql-editor {
  p {
    min-height: 1.42rem;
  }
  &.ql-blank {
    &::before {
      content: none;
    }
    &::after {
      color: rgba(0, 0, 0, 0.6);
      content: attr(data-placeholder);
      display: block;
      font-style: italic;
      margin-top: -1.42em;
      min-height: 1em;
      pointer-events: none;
    }
  }
}
.ql-formats {
  position: relative;
}
