/*************
  BREAKPOINTS
**************/

$breakpoints: (
  'extra-small': '320px',
  'extra-small-up': '321px',
  'small': '600px',
  'medium': '900px',
  'large': '1024px',
  'huge': '1366px',
);

$body-width: 1280 !default;
$browser-context: 16 !default;

/*************
  COLORS
**************/

// LIGHT MODE
$primary: #850599 !default;
$secondary: #171a37 !default;
$text: #171a37 !default;
$error: #990404 !default;
$approved: #189904 !default;
$neutral: #ffa800 !default;
$white: #fff !default;
$black: #000 !default;
$stroke: #e8e8eb !default;

// TO DO - Remove once the redesign is complete
$tertiary: #150236 !default;
$form-text: #61637c !default;
$accent: #fcebfe !default;
$grey: #e3e4ee !default;

// DARK MODE
$primary-dark: #850599 !default;
$secondary-dark: #171a37 !default;
$text-dark: #171a37 !default;
$error-dark: #ff2323 !default;
$approved-dark: #189904 !default;
$neutral-dark: #ffa800 !default;
$stroke-dark: #e8e8eb !default;

// TO DO - Remove once the redesign is complete
$tertiary-dark: #150236 !default;
$form-text-dark: #61637c !default;
$accent-dark: #fcebfe !default;
$grey-dark: #e3e4ee !default;

$oc-colors: (
  --primary: (
    $primary,
    $primary-dark,
  ),
  --secondary: (
    $secondary,
    $secondary-dark,
  ),
  --approved: (
    $approved,
    $approved-dark,
  ),
  --error: (
    $error,
    $error-dark,
  ),
  --tertiary: (
    $tertiary,
    $tertiary-dark,
  ),
  --neutral: (
    $neutral,
    $neutral-dark,
  ),
  --white: (
    $white,
    $white,
  ),
  --black: (
    $black,
    $black,
  ),
  --text: (
    $text,
    $text-dark,
  ),
  --form-text: (
    $form-text,
    $form-text-dark,
  ),
  --grey: (
    $grey,
    $grey-dark,
  ),
  --accent: (
    $accent,
    $accent-dark,
  ),
  --stroke: (
    $stroke,
    $stroke-dark,
  ),
);

/*************
  FONTS
**************/

// Declared font families.
$font-bold: 'Roboto-bold', sans-serif !default;
$font-medium: 'Roboto-medium', sans-serif !default;
$font-regular: 'Roboto-regular', sans-serif !default;
$font-flex: 'Roboto-flex', sans-serif !default;
$defaultFontFamily: $font-flex;

$typographies: (
  page-title: (
    font-family: $font-flex,
    font-size: 24px,
    font-weight: 700,
    line-height: 36px,
  ),
  field-title: (
    font-family: $font-flex,
    font-size: 16px,
    line-height: 32px,
    font-weight: 700,
  ),
  field-title-sm: (
    font-family: $font-flex,
    font-size: 14px,
    line-height: 26px,
    font-weight: 700,
  ),
  field-text: (
    font-family: $font-flex,
    font-size: 16px,
    line-height: 32px,
    font-weight: 400,
  ),
  under-field-text: (
    font-family: $font-flex,
    font-size: 12px,
    line-height: 20px,
    font-weight: 400,
  ),
  pop-up-message-title: (
    font-family: $font-flex,
    font-size: 18px,
    line-height: 32px,
    font-weight: 700,
  ),
  pop-up-message-body: (
    font-family: $font-flex,
    font-size: 16px,
    line-height: 32px,
    font-weight: 400,
  ),
  old-md: (
    font-family: $font-flex,
    font-size: 20px,
    line-height: 32px,
  ),
  old-sm: (
    font-family: $font-flex,
    font-size: 14px,
    line-height: 30px,
    font-weight: 400,
  ),
);

// Sizes
$size-sm: 8px;
$size-md: 16px;
$size-lg: 32px;
$size-xl: 64px;
$size-xxl: 128px;

/*************
  OLD COLORS REMOVE
**************/

$color-map: (
  app-background: (
    primary: #fcfcfc,
    tertiary: #f2f2f2,
    header: #f8f8f8,
  ),
);

/*************
  OC ICONS
**************/

$icon-map: (
  db: '\e800',
  eye: '\e801',
  nfc: '\e802',
  trash: '\e803',
  upload: '\e804',
  file: '\e805',
  bitcoin: '\e806',
  calendar-alt: '\e807',
  calendar: '\e808',
  dollar: '\e809',
  download: '\e80a',
  edit: '\e80b',
  xlsx: '\e80c',
  check-mark: '\e80d',
  application: '\e80e',
  database: '\e80f',
  user: '\e810',
  db-aggregation: '\e811',
  pdf: '\e812',
  angle-up: '\f106',
  angle-down: '\f107',
);

/*************
  IMAGES
**************/
$logo-img: url('/assets/images/Logo.svg') !default;
$logo-img-dark: url('/assets/images/Logo.svg') !default;
$logo-register-img: url('/assets/images/logo_magenta.png') !default;
$logo-register-img-dark: url('/assets/images/logo_magenta.png') !default;
$logo-footer-img: url('/assets/images/Footer-Logo.svg') !default;
$logo-footer-img-dark: url('/assets/images/Footer-Logo.svg') !default;
$loader-img: url('/assets/images/animatedLogo.gif') !default;
$loader-img-dark: url('/assets/images/animatedLogo.gif') !default;

$oc-images: (
  --logo: (
    $logo-img,
    $logo-img-dark,
  ),
  --logo-footer: (
    $logo-footer-img,
    $logo-footer-img-dark,
  ),
  --loader: (
    $loader-img,
    $loader-img-dark,
  ),
  --logo-register: (
    $logo-register-img,
    $logo-register-img-dark,
  ),
);

/*************
  ELEVATIONS
**************/
$elevations: (
  elevation1: (
    0px 2px 6px rgba(23, 26, 55, 0.05),
    0px 5px 20px rgba(23, 26, 55, 0.02),
  ),
  elevation2: (
    0px 2px 6px rgba(0, 0, 0, 0.1),
    0px 4px 15px rgba(23, 26, 55, 0.05),
  ),
  elevation3: (
    0px 5px 10px rgba(23, 26, 55, 0.08),
    0px 5px 20px rgba(23, 26, 55, 0.1),
  ),
  elevation4: (
    0px 2px 6px rgba(23, 26, 55, 0.2),
    0px 5px 40px rgba(23, 26, 55, 0.2),
  ),
);
