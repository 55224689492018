@font-face {
  font-family: 'oc-icons';
  src: url('/assets/fonts/oc-icons/oc-icons.eot?39235680');
  src: url('/assets/fonts/oc-icons/oc-icons.eot?39235680#iefix') format('embedded-opentype'),
    url('/assets/fonts/oc-icons/oc-icons.woff2?39235680') format('woff2'),
    url('/assets/fonts/oc-icons/oc-icons.woff?39235680') format('woff'),
    url('/assets/fonts/oc-icons/oc-icons.ttf?39235680') format('truetype'),
    url('/assets/fonts/oc-icons/oc-icons.svg?39235680#oc-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'oc-icons';
    src: url('/assets/fonts/oc-icons/oc-icons.svg?39235680#oc-icons') format('svg');
  }
}
*/
[class^='oc-icon']:before,
[class*=' oc-icon']:before {
  font-family: 'oc-icons', sans-serif;
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;

  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.oc-icon {
  &--db:before {
    content: '\e800';
  } /* '' */
  &--eye:before {
    content: '\e801';
  } /* '' */
  &--nfc:before {
    content: '\e802';
  } /* '' */
  &--trash:before {
    content: '\e803';
  } /* '' */
  &--upload:before {
    content: '\e804';
  } /* '' */
  &--file:before {
    content: '\e805';
  } /* '' */
  &--bitcoin:before {
    content: '\e806';
  } /* '' */
  &--calendar-alt:before {
    content: '\e807';
  } /* '' */
  &--calendar:before {
    content: '\e808';
  } /* '' */
  &--dollar:before {
    content: '\e809';
  } /* '' */
  &--download:before {
    content: '\e80a';
  } /* '' */
  &--edit:before {
    content: '\e80b';
  } /* '' */
  &--xlsx:before {
    content: '\e80c';
  } /* '' */
  &--check-mark:before {
    content: '\e80d';
  } /* '' */
  &--application:before {
    content: '\e80e';
  } /* '' */
  &--database:before {
    content: '\e80f';
  } /* '' */
  &--user:before {
    content: '\e810';
  } /* '' */
  &--db-aggregation:before {
    content: '\e811';
  } /* '' */
  &--pdf:before {
    content: '\e812';
  } /* '' */
  &--angle-up:before {
    content: '\f106';
  } /* '' */
  &--angle-down:before {
    content: '\f107';
  } /* '' */
}
