survey-creator {
  label {
    margin: 0;
  }
  .svc-creator__banner {
    display: none;
  }
}

.consent-survey {
  .sd-question {
    &__required-text {
      color: var(--secondary-60);
    }
  }

  .sd-dropdown {
    align-items: center;
    &__value {
      .sv-string-viewer {
        font-family: $font-regular;
        font-weight: 400;
      }
    }
  }

  .sd-dropdown_clean-button {
    padding: 0 calc(1 * var(--sjs-base-unit, var(--base-unit, 8px)));
  }

  .sv-tagbox__item {
    align-items: center;

    .sv-string-viewer {
      color: var(--white);
    }
  }

  .sd-list {
    &__item {
      &--selected {
        .sv-string-viewer {
          color: var(--white);
        }
      }
    }
  }

  label {
    &.sd-boolean {
      margin: 0;
    }

    &.sd-selectbase {
      &__label {
        align-items: center;
        margin-bottom: 0;
      }
    }

    &.sd-rating {
      &__item {
        &--selected {
          .sv-string-viewer {
            color: var(--white);
          }
        }
      }
    }
  }

  .sd-root-modern {
    background: transparent;
  }

  .sd-body .sd-body__page {
    min-width: 100%;
  }

  .sd-element {
    &__title {
      span {
        &.sd-element__num {
          margin-left: 0;
        }
      }
    }

    &--with-frame {
      padding: rem(12);

      & > .sd-question {
        &__erbox {
          &--outside-question {
            font-size: rem(16);
            margin: 0 0 rem(16) 0;
            width: auto;
          }
        }
      }
    }
  }

  .sd-row {
    &.sd-page__row {
      & > div {
        min-width: auto !important;
      }
    }
  }
  .sd-root-modern--mobile .sd-body.sd-body--responsive,
  .sd-root-modern--mobile .sd-body.sd-body--static {
    padding: rem(8);
  }

  .sd-completedpage {
    h3,
    h4 {
      font-size: rem(24);
      padding: 0 rem(8);
    }
  }

  .sd-item {
    padding: rem(8) 0;
    .sd-element {
      &__title {
        color: var(--sjs-font-questiontitle-color);
      }
    }

    &__control-label {
      color: var(--sjs-general-forecolor);
    }
  }

  .sv-list {
    &__item-body {
      color: var(--text-60);
    }
  }

  input {
    &.sd-btn {
      &--action {
        &:hover {
          background-color: var(--sjs-primary-backcolor-light);
        }
      }
    }
  }

  .sd-root--compact .sd-body__navigation .sd-btn:not(.sd-btn--action) {
    background-color: var(--sjs-primary-backcolor, var(--primary, #19b394));
    color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    &:hover {
      background-color: var(--sjs-primary-backcolor-light, var(--primary-60, #19b394));
      color: var(--sjs-primary-forecolor, var(--primary-foreground, #fff));
    }
  }

  .sd-dropdown__filter-string-input::placeholder,
  .sd-dropdown__value {
    color: var(--text-60);
  }

  .sd-dropdown--empty:not(.sd-input--disabled),
  .sd-dropdown--empty:not(.sd-input--disabled) .sd-dropdown__value {
    color: var(--text-60);
  }

  @include for-desktop-up {
    .sd-completedpage {
      h3,
      h4 {
        font-size: var(--sjs-article-font-large-fontSize, 32px);
        font-size: rem(24);
        padding: 0;
      }
    }
    .sd-row {
      &.sd-page__row {
        & > div {
          min-width: rem(300);
        }
      }
    }

    .sd-item {
      padding: calc(1.5 * var(--sjs-base-unit, var(--base-unit, 8px))) 0;
    }
  }
}
