// MEDIA QUERIES
@mixin for-phone-only {
  @media (max-width: breakpoint('extra-small')) {
    @content;
  }
}

@mixin for-phone-up {
  @media (min-width: breakpoint('extra-small-up')) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: breakpoint(small)) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: breakpoint(medium)) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: breakpoint(large)) {
    @content;
  }
}
@mixin for-desktop-large {
  @media (min-width: breakpoint(huge)) {
    @content;
  }
}

@mixin formBasicElement {
  @include typography(field-text);
  background: var(--white);
  border: 2px solid var(--grey);
  border-radius: rem(4);
  color: var(--text-60);
  height: rem(32);
  padding: rem(8) rem(8);
  width: 100%;
  @include for-big-desktop-up {
    padding: rem(8) rem(16);
  }
  @include for-desktop-up {
    border-radius: rem(8);
    height: rem(48);
  }
}

@mixin formElement {
  @include formBasicElement();
  margin-bottom: rem(24);

  &:disabled {
    background-color: var(--grey-30);
    opacity: 1;
  }
  &.ng-invalid {
    &.ng-touched {
      border-color: var(--error-60);
    }
  }
  @include for-big-desktop-up {
    margin-bottom: 0;
  }
  @include for-desktop-up {
    margin-bottom: rem(36);
  }
}

@mixin grid {
  display: grid;
  gap: $grid-gap;
  grid-template-columns: repeat(8, 1fr);
}

@mixin backgroundGradient {
  background: linear-gradient(92.16deg, var(--primary) -8.56%, var(--primary-darker) 106.13%);
}

@mixin fullAvailableHeight {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin oc-icon($icon) {
  &:before {
    font-family: 'oc-icons', sans-serif;
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: map-get($map: $icon-map, $key: $icon);
  }
}

@mixin typography($type) {
  font-family: map-get(map-get($typographies, $type), font-family);
  font-weight: map-get(map-get($typographies, $type), font-weight);
  font-size: map-get(map-get($typographies, $type), font-size);
  line-height: map-get(map-get($typographies, $type), line-height);
}

@mixin linkStyle {
  background: transparent;
  border: none;
  color: var(--primary);
  cursor: pointer;
  font-weight: 400;
  line-height: rem(22);
  &:hover {
    color: var(--primary);
    line-height: rem(22);
    text-decoration: underline;
  }

  &.disabled {
    cursor: no-drop;
    color: var(--primary-60);
    &:hover {
      font-weight: 400;
      text-decoration: none;
    }
  }
}

@mixin elevation($type) {
  box-shadow: map-get($elevations, $type);
}

@mixin surveyRequest {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  @include for-tablet-portrait-up {
    margin: 0 auto;
    max-height: 100%;
    width: 100%;
  }

  @include for-big-desktop-up() {
    max-width: rem(500);
  }
}
