$table-padding: 0 rem(12);
table {
  &.table {
    border: 1px solid var(--text-30);
    border-collapse: separate !important;
    border-radius: 8px;
    border-spacing: 0;
    color: var(--text);
    font-size: 14px;
    margin-bottom: 0;
    overflow: hidden;
    thead {
      background-color: var(--grey-60);
      th {
        border-bottom: 2px solid var(--grey-60);
        border-top: none;
        font-family: $font-regular;
        font-size: 14px;
        font-weight: 400;
        height: rem(48);
        padding: $table-padding;
        position: relative;
        vertical-align: middle;

        &.sortable {
          cursor: pointer;
          &:hover {
            filter: brightness(150%);
          }
          &:before {
            position: absolute;
            right: 0;
            top: calc(50% - 1em / 2);
          }
          &--asc {
            @include oc-icon('angle-up');
          }
          &--desc {
            @include oc-icon('angle-down');
          }
        }
      }
    }
    tbody {
      tr {
        td,
        th {
          border: none;
          height: rem(40);
          padding: $table-padding;
          vertical-align: middle;
        }
        &.selected {
          background-color: var(--primary-10);
        }
        &:not(.selected) {
          &:not(:last-child) {
            td {
              border-bottom: 2px solid var(--grey-60);
            }
          }
          &:nth-child(even) {
            background-color: var(--grey-10);
          }
          &:nth-child(odd) {
            background-color: var(--white);
          }
          &:hover {
            background-color: var(--primary-05);
          }
        }
      }
    }

    td,
    th {
      max-width: rem(100);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
