* {
  scrollbar-color: var(--text-10);

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--text-10);
    border-radius: 20px;
  }
}
body {
  background: white;
}
a,
.a-link {
  background: transparent;
  border: none;
  color: var(--primary);
  line-height: rem(22);
  padding: 0;
  &:hover {
    &:not([disabled]):not(.disabled):not(.nav-link) {
      color: var(--primary);
    }
  }

  &.disabled {
    cursor: no-drop;
    color: var(--primary-60);
    &:hover {
      font-weight: 400;
      text-decoration: none;
    }
  }
}

/* ==========================================================================
Catalog and Datasource details
Styles definition
========================================================================== */
.details-section {
  app-image-drop-zone,
  .logo {
    display: block;
    height: rem(100);
    width: rem(100);
  }
  .details {
    @include flexRow;
    margin-bottom: rem(16);
    .logo {
      display: flex;
      align-items: center;
      background: var(--white);
      border-radius: rem(20);
      box-shadow: 0px 2px 4px 0px #00000040;
      padding: rem(8);
      .image {
        height: inherit;
        max-height: 100%;
        max-width: 100%;
        width: inherit;
      }
    }
    &__attributes {
      @include fullAvailableHeight;
      justify-content: flex-start;
      margin-left: rem(20);
      b {
        font-weight: bold;
      }
      .preview {
        margin-top: rem(12);
      }
    }
    &__attr {
      align-items: center;
      color: var(--text);
      display: inline-block;
      font-family: $font-regular;
      button {
        margin-left: 0;
        padding-left: 0;
      }
      label {
        font-family: $font-bold;
        margin-right: rem(4);
        margin-bottom: 0;
      }
      span {
        display: inline-block;
      }
      &--break-line {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
      }
      &--separation {
        margin-bottom: rem(32);
      }
      &--mobile {
        display: inherit;
      }
      &--tablet-up {
        display: none;
      }
    }
    &__description {
      margin-top: rem(38);
      &--title {
        font-weight: bold;
      }
    }
    &__license {
      @include flexRow;
      margin-top: rem(36);
      &--title {
        font-weight: bold;
        margin-right: rem(14);
      }
    }
  }
  .head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    .title {
      order: 1;
      h1 {
        margin-bottom: rem(12);
      }
    }
    .actions {
      order: 3;
      button {
        & + button {
          margin-left: rem(16);
        }
      }
      margin-bottom: rem(36);
    }
    .status-section {
      flex: 0 0 100%;
      margin-bottom: rem(36);
      order: 2;
    }
  }
  @include for-tablet-portrait-up {
    app-image-drop-zone,
    .logo {
      height: rem(170);
      width: rem(170);
    }
    .details {
      margin-bottom: rem(36);
      &__attributes {
        margin-left: rem(47);
      }
      &__attr {
        button {
          margin-left: rem(16);
        }
        &--mobile {
          display: none;
        }
        &--tablet-up {
          display: inline-block;
        }
        &--separation {
          margin-bottom: rem(36);
        }
      }
    }

    .head {
      @include flexRow;
      .title {
        order: inherit;
        h1 {
          margin-bottom: rem(24);
        }
      }
      .actions {
        order: inherit;
        margin-bottom: 0;
      }
      .app-status {
        order: inherit;
        margin-bottom: 0;
      }
    }
  }
}

/* ==========================================================================
Pages basic styling
section > Title + intro follow the same styling
========================================================================== */
section {
  color: var(--text);
  h1 {
    margin-bottom: rem(24);
    & + p {
      @include typography(field-text);
      margin-bottom: rem(36);
    }
  }
  @include for-big-desktop-up {
    h1 {
      margin-bottom: rem(16);
      & + p {
        @include typography(old-md);
        margin-bottom: rem(56);
      }
    }
  }
}

.boxed {
  background-color: var(--grey-30);
  border: 1px solid var(--grey);
  border-radius: 4px;
  padding: rem(24) rem(28) rem(16);
  h1 {
    font-size: rem(24);
    margin-bottom: rem(48);
  }
  @include for-desktop-large {
    padding: rem(24) rem(56) rem(16);
  }
}

/* ==========================================================================
Helpers
========================================================================== */
hr {
  border: solid 2px rgba(0, 0, 0, 0.03);
}

.box-section {
  @include flexRow;
  justify-content: space-between;
  background: clr(app-background, tertiary);
  border-radius: rem(10);
  margin-bottom: rem(40);
  padding: rem(12) rem(18);

  &--attributes {
    @include fullAvailableHeight;
    padding-bottom: rem(28);
  }
}

.empty-label {
  @include for-big-desktop-up {
    display: block;
    height: rem(32);
    margin-bottom: 0.5rem;
  }
}

.input-group {
  input[type='text'] {
    width: 1%;
  }
}

.no-margin {
  margin: 0 !important;
}

.status {
  align-items: center;
  background-color: var(--error-30);
  border-color: var(--error-30);
  border-radius: rem(20);
  color: var(--error);
  display: flex;
  font-family: $font-bold;
  font-size: rem(10);
  height: rem(17);
  justify-content: center;
  padding: rem(4) rem(13);
  text-transform: uppercase;
  width: fit-content;
  & > * {
    font-family: $font-bold;
  }

  &--live,
  &--approved {
    background-color: var(--approved-30);
    border-color: var(--approved-30);
    color: var(--approved);
  }

  &--removable,
  &--non-removable {
    color: var(--white);
    border-radius: rem(32);
    font-family: $font-regular;
    font-size: rem(12);
    height: rem(22);
    justify-content: space-between;
    padding: rem(4) rem(10);
    text-transform: none;
    & > * {
      color: var(--white);
      font-family: $font-regular;
    }
    button {
      background: transparent;
      border: none;
      margin: 0;
      padding: 0 0 0 rem(5);
    }
    span {
      margin-right: rem(10);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--removable {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  &--non-removable {
    background-color: var(--secondary);
    border-color: var(--secondary);
  }

  &--default {
    background-color: #f5f5f5;
    border-color: #f5f5f5;
    color: var(--text);
  }
}

:root {
  --defaultFontFamily: #{$defaultFontFamily};
  --primary-darker: #{darken($primary, 10%)};
  @each $name, $color in $oc-colors {
    $current: nth($color, 1);
    #{$name}: $current;
    #{$name}-rgb: (#{red($current)}, #{green($current)}, #{blue($current)});
    #{$name}-60: rgba($color: $current, $alpha: 0.6);
    #{$name}-30: rgba($color: $current, $alpha: 0.3);
    #{$name}-10: rgba($color: $current, $alpha: 0.1);
    #{$name}-05: rgba($color: $current, $alpha: 0.05);
  }

  @each $name, $img in $oc-images {
    $current: nth($img, 1);
    #{$name}: $current;
  }
}

.theme-dark {
  --primary-darker-dark: #{darken($primary-dark, 10%)};
  @each $name, $color in $oc-colors {
    $current: nth($color, 2);
    #{$name}: $current;
    #{$name}-rgb: (#{red($current)}, #{green($current)}, #{blue($current)});
    #{$name}-60: rgba($color: $current, $alpha: 0.6);
    #{$name}-30: rgba($color: $current, $alpha: 0.3);
    #{$name}-10: rgba($color: $current, $alpha: 0.1);
    #{$name}-05: rgba($color: $current, $alpha: 0.05);
  }

  @each $name, $img in $oc-images {
    $current: nth($img, 2);
    #{$name}: $current;
  }
}
