/* import predefined variables / colors / mixins */
@import 'sass-helpers.scss';

/* register margin variables */
$register-title-margin-bottom: $size-md;
$register-input-margin-between: $size-md;
$register-input-margin-bottom: $size-xl;
$register-button-margin-bottom: $size-xl;
$register-text-margin-bottom: $size-xl;
$grid-gap: $size-md;

/* register mobile style */
.register {
  align-items: center;
  background: rgba(57, 60, 91, 0.2);
  height: 100%;
  overflow: auto;

  form {
    padding: 0 rem(16) rem(60);
    input {
      margin-bottom: 0;
      box-shadow: none;
      border: none;
    }
  }

  &__group {
    & + .register__group {
      margin-top: rem(36);
    }
  }

  &__section {
    margin: 0;
  }

  &__label,
  &__label-input {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0;
  }

  &__label-input {
    input {
      margin-right: 5px;
    }
    span {
      color: var(--primary-60);
    }
  }

  &__line {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    background-color: var(--primary);
    margin-bottom: rem(36);
    margin-top: 0;
    padding: rem(64) rem(32) rem(16);
    text-align: center;

    span {
      background: var(--logo-register);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border: 0;
      display: inline-block;
      margin-bottom: rem(10);
      min-height: rem(190);
      outline: 0;
      width: rem(250);
    }
    p {
      @include typography(under-field-text);
      color: var(--white);
      text-align: center;
    }
  }

  &__button {
    background-color: var(--primary);
    border-radius: 4px;
    color: var(--white);
    display: block;
    width: 100%;
    &:disabled {
      background: #ffffff;

      border: 2px solid var(--primary-30);
      color: var(--primary-30);
    }
  }

  &__input {
    border-radius: $size-sm;
    display: block;
    grid-column: 1/9;
    margin-bottom: $register-input-margin-between;
    padding: $size-sm $size-md;
    width: 100%;
    &::placeholder {
      color: var(--text);
    }

    &:not(:read-only) {
      background: var(--white);
    }
  }

  &__info {
    display: block;
    font-size: rem(12);
    font-style: italic;
    line-height: rem(16);
    margin-top: rem(4);
  }

  ::ng-deep {
    app-alert {
      .alerts {
        top: rem(36);
      }
    }
    app-custom-checkbox {
      .custom-checkbox {
        align-items: flex-start;
        &__label {
          line-height: rem(18);
          margin-left: rem(16);
        }
      }
    }
  }
}

/* register tablet style */
@include for-tablet-portrait-up {
  .register {
    form {
      padding: 0 0 rem(60);
      margin: 0 auto;
      width: rem(397);
    }
    &__label-input {
      margin-top: rem(8);
    }
    &__logo {
      padding: rem(96) rem(100) rem(24);
      margin-bottom: rem(36);

      p {
        padding: 0 rem(106);
      }
    }
    &__section {
      margin: 0 0 rem(36);
    }

    ::ng-deep {
      app-alert {
        .alerts {
          top: rem(36);
        }
      }
    }
  }
}

/* register desktop styles */
@include for-desktop-up {
  .register {
    height: 100vh;
    form {
      padding: 0 rem(60) rem(55);
      width: 100%;
    }
    &__group {
      & + .register__group {
        margin-top: rem(18);
      }
    }
    &__line {
      display: block;
    }
    &__logo {
      padding: rem(64) rem(50) rem(25);
      margin-bottom: rem(18);
      p {
        padding: 0;
      }
    }
    &__section {
      margin: 0 auto;
      width: rem(600);
    }

    ::ng-deep {
      app-alert {
        .alerts {
          position: sticky;
          top: rem(160);
          .alert {
            &.password-warning {
              padding: rem(42);
              width: rem(912);

              .alert__icon {
                height: rem(158);
                left: 0;
                position: relative;
                top: 0;
                width: rem(188);
              }

              .alert__text {
                padding-left: rem(24);
                h4 {
                  font-size: rem(36);
                  line-height: rem(42);
                  padding: 0;
                  margin-bottom: rem(20);
                }

                span {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
      app-custom-checkbox {
        .custom-checkbox {
          align-items: center;
          &__label {
            line-height: rem(32);
            margin-left: rem(9);
          }
        }
      }
    }
  }
}
