/* ==========================================================================
Nav Tabs
Bootstrap styles overwrited
========================================================================== */
.nav-tabs {
  border-bottom: none;
  display: flex;
  .nav-item {
    flex: 1;
    text-align: center;
    .nav-link {
      @include typography(field-title);
      align-items: center;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 2px solid var(--text-10);
      border-radius: 0;
      color: var(--text);
      font-family: $font-regular;
      font-size: rem(14);
      height: rem(48);
      justify-content: center;
      transition: background-color 0.2s;
      &.active {
        background: var(--secondary-10);
        border-bottom-color: var(--text);
      }
      &.disabled {
        background-color: transparent;
        border-color: transparent;
        color: #6c757d;
        cursor: default;
      }
      &:hover {
        background: var(--secondary-10);
        border-bottom-color: var(--text);
        transition: background-color 0.2s;
      }
    }
  }
}

/* ==========================================================================
Dropdown
Bootstrap styles overwrited
========================================================================== */
.dropdown-menu {
  --oc-dropdown-bg: var(--white);
  border-radius: rem(8);
  max-height: rem(320);
  overflow: auto;
  padding: 0;
  margin: 0;
  width: 100%;

  .dropdown-item {
    border-radius: 0;
    border: none;
    color: var(--text);
    cursor: pointer;
    &:hover {
      color: var(--primary);
      background-color: var(--primary-10);
    }
    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.dropdown {
  &.oc-organizations__dropdown {
    .dropdown-item {
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding: 0 rem(20);
    }
  }

  &--dots {
    .dropdown-item {
      display: flex;
      justify-content: space-between;
    }
    .dropdown-toggle {
      border: none;
      padding: 0;
      width: 100%;
      &:after {
        border: none;
        content: '\2807';
        cursor: pointer;
        color: var(--text-60);
        font-size: rem(26);
        margin-left: rem(10) !important;
        transition: 0.2s;
      }
      &:disabled {
        background-color: transparent;
        opacity: 0.4;
      }
      &:hover {
        &:not(:disabled) {
          &::after {
            filter: drop-shadow(5px 5px 10px #000);
            color: var(--text);
          }
        }
      }
    }
  }
}

/* override hover color for dropdown-item forced by bootstrap to all a:not([href]):not([tabindex]) elements in _reboot.scss */
a:not([href]):not([tabindex]):hover.dropdown-item {
  color: var(--primary-10);
}

/* override .dropdown-item.active background-color on hover */
.dropdown-item.active:hover {
  background-color: var(--primary-10);
}

.dropdown-item:hover {
  color: var(--primary-10);
}

/* ==========================================================================
Modal Dialog
Bootstrap styles overwrited
========================================================================== */
.modal-backdrop {
  z-index: 1040 !important;
}
.modal {
  .modal-dialog {
    &.modal-600 {
      max-width: 600px;
    }
    .modal-content {
      background: transparent;
      border-radius: rem(8);
    }
  }

  &.survey-modal {
    .modal-dialog {
      max-width: 95%;
    }
    .modal-dialog-scrollable {
      height: calc(100% - 3.5rem);
    }

    .modal-content,
    app-consent-survey-builder,
    app-modal,
    .app-modal {
      height: 100%;
      main {
        height: calc(100% - 6.5rem);
      }

      .survey-creator {
        height: 100%;
      }
    }
  }

  &.update-app-modal {
    .modal-dialog {
      max-width: auto;
    }
  }

  @include for-tablet-portrait-up {
    &.update-app-modal {
      .modal-dialog {
        max-width: 96%;
      }
    }
  }
}

/* ==========================================================================
Bootstrap Datepicker
Styles definition
========================================================================== */
.ngb-tp {
  .ngb-tp-input-container {
    input[type='text'].form-control {
      margin-bottom: 0;
      padding: rem(8) rem(8);
    }
  }
  .visually-hidden {
    display: none;
  }
}

/* ==========================================================================
Bootstrap Carousel
Styles definition
========================================================================== */
.pagination {
  flex-wrap: wrap;
  height: rem(48);
  justify-content: space-between;
  margin-bottom: 0;
  .page-item {
    .page-link {
      align-items: center;
      background-color: transparent;
      border: none;
      border-radius: 50%;
      color: var(--secondary-60);
      display: flex;
      font-size: 14px;
      font-family: $font-regular;
      height: rem(40);
      justify-content: center;
      &:not([aria-label='Previous']):not([aria-label='Next']) {
        width: rem(40);
      }
      &:focus {
        box-shadow: 0 0 0 0.2rem var(--primary-30);
      }
      &:hover {
        text-decoration: none;
      }
    }

    &.active {
      .page-link {
        background: var(--primary);
        color: var(--white);
      }
    }
    &.disabled {
      cursor: default;
      opacity: 0.3;
    }

    &:not(:first-child):not(:last-child) {
      display: none;
    }

    &:not(:first-child) {
      margin-left: rem(12);
    }
  }

  @include for-tablet-portrait-up {
    .page-item {
      &:not(:first-child):not(:last-child) {
        display: flex;
      }
    }
  }
  @include for-big-desktop-up {
    justify-content: center;
  }
}

.btn {
  &:active,
  &:focus {
    box-shadow: none;
  }
}

.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.visually-hidden {
  display: none;
}

/* ==========================================================================
Bootstrap Accordion
Styles definition
========================================================================== */
.accordion {
  // scss-docs-start accordion-css-vars
  --#{$prefix}accordion-transition: #{$accordion-transition};
  --#{$prefix}accordion-btn-icon: #{escape-svg($accordion-button-icon)};
  --#{$prefix}accordion-btn-icon-width: #{$accordion-icon-width};
  --#{$prefix}accordion-btn-icon-transform: #{$accordion-icon-transform};
  --#{$prefix}accordion-btn-icon-transition: #{$accordion-icon-transition};
  --#{$prefix}accordion-btn-active-icon: #{escape-svg($accordion-button-active-icon)};
  // scss-docs-end accordion-css-vars

  .accordion-button {
    align-items: center;
    display: flex;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--#{$prefix}accordion-btn-active-icon);
    transform: var(--#{$prefix}accordion-btn-icon-transform);
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: var(--#{$prefix}accordion-btn-icon-width);
    height: var(--#{$prefix}accordion-btn-icon-width);
    margin-left: auto;
    content: '';
    background-image: var(--#{$prefix}accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--#{$prefix}accordion-btn-icon-width);
    @include transition(var(--#{$prefix}accordion-btn-icon-transition));
  }
}

/* ==========================================================================
Tooltip
Bootstrap styles overwrited
========================================================================== */
.tooltip {
  &.oc-tooltip {
    .tooltip-inner {
      background: var(--white);
      box-shadow: 0px 5px 10px rgb(23 26 55 / 8%), 0px 5px 20px rgb(23 26 55 / 10%);
      border: 2px solid var(--grey);
      border-radius: 0.5rem;
      color: var(--text-60);
      padding: rem(8);
      text-align: left;
      width: rem(360);
      max-width: rem(360);
    }
  }
}
