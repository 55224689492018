// Search bar
.oneCreation-main {
  .app-search-bar {
    display: block;
    position: relative;
    &__input,
    input[type='text'] {
      background: var(--white);
      border: 2px solid var(--text-10);
      border-radius: rem(8);
      box-shadow: none;
      font-size: rem(14);
      height: rem(32);
      margin-bottom: 0;
      padding: rem(8) rem(32) rem(8) rem(16);
      width: 100%;
      &::placeholder {
        color: var(--text-60);
        font-style: normal;
      }
      &:focus {
        ::placeholder {
          color: transparent;
        }
      }
      &:focus::placeholder {
        color: transparent;
      }
    }
    &.disabled {
      input {
        border-color: #f2f3f3;
        background: rgba(0, 0, 0, 0.03);
        &::placeholder {
          color: var(--text-60);
        }
      }
      &::after {
        opacity: 30%;
      }
    }
    &::after {
      content: '';
      background: url(/assets/images/Search.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: rem(14);
      display: inline-block;
      height: 100%;
      right: rem(10);
      position: absolute;
      top: 0;
      width: rem(21);
    }
  }
}
