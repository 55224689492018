$tablet: '600px';
$desktop: '1024px';
@mixin for-tablet-portrait-up {
  @media (min-width: $tablet) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $desktop) {
    @content;
  }
}

.one-creation {
  * {
    font-family: 'Roboto', sans-serif;
  }

  .auth0-lock {
    &.auth0-lock {
      background: rgba($color: #393c5b, $alpha: 0.1);

      .auth0-lock-cred-pane-internal-wrapper {
        height: auto;
        padding-bottom: 50px;
      }
      .auth0-lock-name {
        font-size: 12px;
        line-height: 20px;
        margin-top: 10px;
        letter-spacing: 0.12px;
        padding: 0 16px;
      }
      .auth0-lock-input-wrap {
        background: #ffffff;
        border-radius: 8px;
        border: 0;
        .auth0-lock-input {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          color: #61637c;
          height: 42px;
        }
      }

      .auth0-lock-header-logo {
        height: auto;
        margin-bottom: 0;
      }
      .auth0-lock-header {
        border-radius: 0;
        color: #ffffff;
        font-size: 20px;
        line-height: 32px;
        padding: 64px 0 16px;
      }

      .auth0-lock-submit {
        align-items: center;
        background-color: #850599 !important;
        border: 1px solid #850599;
        box-sizing: border-box;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        display: flex !important;
        height: 48px;
        justify-content: center;
        left: 0px;
        margin: 0 auto;
        padding: 8px 16px;
        position: static;
        top: 0px;
        width: 80%;
      }
      .auth0-lock-form {
        padding: 0;
      }

      .auth0-lock-overlay {
        background: transparent;
      }

      .auth0-lock-header-bg {
        background: #850599;
        .auth0-lock-header-bg-blur,
        .auth0-lock-header-bg-solid {
          opacity: 0;
        }
      }

      .auth0-lock-content-body-wrapper {
        padding-top: 0;
        overflow-x: inherit;
      }

      .auth0-lock-content-wrapper {
        margin-bottom: 20px;
        overflow-x: inherit;
      }

      &.auth0-lock-opened {
        .auth0-lock-widget {
          box-shadow: none;
          height: 100%;
        }

        .auth0-lock-body-content {
          background: transparent;
        }

        .auth0-lock-header-bg {
          .auth0-lock-header-bg-blur {
            opacity: 0;
            transition: none;
          }
        }
      }

      .auth0-lock-cred-pane {
        background: transparent;
      }
      .auth0-lock-widget {
        width: 100%;
      }

      .auth0-loading-container .auth0-loading {
        top: -16px;
      }

      .auth0-lock-social-button {
        height: 42px;
        border-radius: 8px;
        &[data-provider^='google'] {
          .auth0-lock-social-button-icon {
            height: 40px;
          }
        }
      }
    }
  }

  .auth0-lock-header-logo {
    max-width: 225px;
  }
  .auth0-lock-content {
    padding: 50px 10% 0;
  }

  input:-internal-autofill-selected {
    background-color: white;
  }

  @include for-desktop-up {
    .auth0-lock {
      &.auth0-lock {
        .auth0-lock-header {
          padding: 64px 0 25px;
        }
        .auth0-lock-cred-pane-internal-wrapper {
          padding-bottom: 0;
        }
        .auth0-lock-name {
          padding: 0 50px;
        }
        .auth0-lock-widget {
          width: 600px;
        }
      }
    }
  }
}
