.datasets-grid {
  display: block;
  max-width: 100%;

  &__headers,
  &__body {
    display: block;
    width: 100%;
  }

  &__body {
    max-height: rem(480);
    overflow: auto;
    overflow-y: scroll;
    overflow-y: overlay;
    padding-top: rem(4);
    padding-bottom: rem(16);
    .datasets-grid__row {
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 4px 15px rgba(23, 26, 55, 0.05);
      border-radius: 10px;
    }
  }

  &__headers {
    div {
      font-weight: bold;
    }
    .datasets-grid__row {
      margin-bottom: 0;
      div {
        &:nth-child(4),
        &:nth-child(5) {
          word-spacing: rem(300);
        }
      }
    }
  }

  &__row {
    align-items: center;
    display: grid;
    grid-template-columns: 12.2% repeat(2, 10%) repeat(4, 7%) 9.8% 30%;
    grid-template-rows: rem(96);
    margin: 0 rem(16) rem(16) rem(4);
    min-height: 0;
    min-width: 0;
    position: relative;
    div {
      color: var(--text-60);
      font-size: rem(14);
      &:first-child {
        padding-left: rem(24);
      }

      &:nth-child(9) {
        display: flex;
        justify-content: space-between;
        padding: 0 rem(16) 0 rem(8);
      }

      a + button,
      button + button {
        margin-left: 0;
      }
      a,
      button {
        font-size: rem(14);
      }
      button {
        padding: 0 rem(14);
        &.app-btn--icon {
          padding: 0 rem(4);
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    a + button {
      margin-left: rem(24);
    }
  }

  &__title {
    align-items: center;
    display: flex;
    font-size: rem(12);
    font-weight: bold;
    position: absolute;
    left: rem(8);
    top: rem(6);
    span {
      font-weight: normal;
      margin-right: rem(8);
      min-width: rem(78);
    }
  }

  &--recipients {
    .datasets-grid {
      &__body {
        max-height: rem(880);
      }
      &__row {
        grid-template-columns: repeat(2, 14%) repeat(2, 10%) 22% 30%;
        div {
          display: flex;
          justify-content: center;
          text-align: center;

          &:nth-child(6) {
            justify-content: flex-end;
            padding-right: rem(32);
            button + button {
              margin-left: rem(16);
            }
          }
        }
      }
    }
  }
}
