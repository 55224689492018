@import 'sass-helpers.scss';

.dataset-detail {
  h1,
  h3 {
    font-size: rem(24);
    font-family: $font-regular;
    font-weight: 600;
  }
  h1 {
    margin-bottom: rem(16);
  }
  h3 {
    margin-bottom: rem(32);
  }

  .section-padded {
    padding-left: rem(32);
  }

  p {
    color: var(--text-60);
    font-size: rem(16);
    margin-bottom: rem(32);
    width: 100%;
  }

  &__aggregated-ds {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: fit-content(30%) fit-content(70%);
    div {
      display: flex;
      align-items: center;
    }
    a {
      text-decoration: underline;
    }
  }

  &__basic-info {
    p {
      label {
        font-weight: bold;
        margin-bottom: 0;
      }
      line-height: rem(24);
      margin-bottom: rem(16);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__header {
    .dataset-detail__owner-actions {
      padding-left: 0;
    }
  }

  &__owner-actions {
    & > div {
      margin-bottom: rem(32);
    }
    button {
      & + button,
      & + a {
        margin-left: rem(32);
      }
    }
  }

  &__sharing-rules {
    border-bottom: 2px solid var(--text-10);
    margin-bottom: rem(32);
    button + button,
    button + a {
      margin-left: rem(32);
      &:hover {
        font-weight: normal;
        text-decoration: none;
      }
    }
    p,
    div {
      text-align: center;
      margin-bottom: 0;
    }
    p {
      margin-bottom: rem(16);
    }
  }

  &__metadata {
    .grid {
      border: 2px solid var(--text-10);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      max-width: rem(1249);
      padding: 0 rem(8) 0 rem(32);
      width: 100%;
      &__headers,
      &__body {
        display: flex;
        width: 100%;
      }

      &__body {
        flex-direction: column;
        max-height: rem(480);
        overflow: auto;
        overflow-y: scroll;
        overflow-y: overlay;
        & > .grid__row {
          &:last-child {
            border-bottom: none;
          }
        }
      }

      &__headers {
        font-weight: bold;
        & > div {
          border-bottom: 2px solid var(--text-10);

          &.grid__row--no-border {
            border-bottom: none;
          }
        }
      }

      &__row {
        align-items: center;
        border-bottom: 2px solid var(--text-10);
        display: flex;
        flex-direction: row;
        flex: 1 0 auto;
        height: rem(56);
        margin-right: rem(24);
        width: 95%;
        & > div {
          color: var(--text-60);
          display: flex;

          &:not(:first-child) {
            flex: 1;
          }
          button + button {
            margin-left: rem(28);
          }
        }

        &--no-border {
          border-bottom: none;
        }
      }

      ::ng-deep {
        .accordion {
          .grid__row {
            div {
              padding-left: var(--oc-accordion-btn-icon-width);
              margin-left: rem(8);
            }
          }
          .accordion-button {
            border: none;
            background: initial;
            padding: 0;
            position: relative;
            width: 100%;
            &::after {
              background-position: center;
              background-image: url('/assets/images/collapse_expand.svg');
              height: 100%;
              left: 0;
              margin-left: 0;
              position: absolute;
            }
          }

          &:last-child {
            .accordion-button {
              &.collapsed {
                .grid__row {
                  border: none;
                }
              }
            }
            .collapse.show {
              .grid__row {
                border: none;
              }
            }
          }
        }
      }
    }
  }

  &--section {
    &:not(:last-child) {
      border-bottom: 2px solid var(--text-10);
      margin-bottom: rem(32);
      padding-bottom: rem(32);
    }
  }

  .policies-grid {
    display: block;
    max-width: 100%;

    &__headers,
    &__body {
      display: block;
      width: 100%;
    }

    &__body {
      max-height: rem(480);
      overflow: auto;
      overflow-y: scroll;
      overflow-y: overlay;
      padding-top: rem(4);
      padding-bottom: rem(16);
      .policies-grid__row {
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1), 0px 4px 15px rgba(23, 26, 55, 0.05);
        border-radius: 10px;
      }
    }

    &__headers {
      div {
        font-weight: bold;
      }
      .policies-grid__row {
        margin-bottom: 0;
        div {
          &:nth-child(4),
          &:nth-child(5) {
            word-spacing: rem(300);
          }
          &:nth-child(8) {
            padding-left: rem(16);
            text-align: left;
          }
        }
      }
    }

    &__row {
      align-items: center;
      display: grid;
      grid-template-columns: 12.2% repeat(2, 10%) repeat(3, 7%) 10% 9.8% 27%;
      grid-template-rows: rem(96);
      margin: 0 rem(16) rem(16) rem(4);
      min-height: 0;
      min-width: 0;
      position: relative;
      div {
        color: var(--text-60);
        font-size: rem(14);
        &:first-child {
          padding-left: rem(24);
        }

        &:nth-child(8) {
          justify-content: flex-start;
          padding-left: rem(16);

          & > div {
            padding-left: 0;
            text-align: left;
          }
        }

        &:nth-child(9) {
          display: flex;
          justify-content: space-between;
          padding: 0 rem(16) 0 rem(8);

          button {
            &:first-child {
              padding-left: 0;
            }
          }
        }

        a + button,
        button + button {
          margin-left: 0;
        }
        a,
        button {
          font-size: rem(14);
        }
        button {
          padding: 0 rem(14);
          &.app-btn--icon {
            padding: 0 rem(4);
            &:hover {
              background-color: transparent;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      a + button {
        margin-left: rem(24);
      }
    }

    &__title {
      align-items: center;
      display: flex;
      font-size: rem(12);
      font-weight: bold;
      position: absolute;
      left: rem(8);
      top: rem(6);
      span {
        font-weight: normal;
        margin-right: rem(8);
        min-width: rem(78);
      }
    }

    &--recipients {
      .policies-grid {
        &__headers {
          div {
            &:nth-child(5) {
              justify-content: flex-start;
              padding-left: rem(16);
              text-align: left;
            }
          }
        }
        &__body {
          max-height: rem(880);
        }
        &__row {
          grid-template-columns: repeat(2, 14%) repeat(2, 10%) 18% 8% 26%;
          div {
            display: flex;
            justify-content: center;
            text-align: center;
            &:nth-child(5) {
              justify-content: flex-start;
              padding-left: rem(16);

              & > div {
                padding-left: 0;
                text-align: left;
              }
            }

            &:nth-child(6) {
              justify-content: flex-start;
              padding-left: rem(8);
            }

            &:nth-child(7) {
              justify-content: flex-end;
              padding-right: rem(32);
              button + button {
                margin-left: rem(16);
              }
            }
          }
        }
      }
    }
  }

  .status {
    &--created,
    &--deactivated {
      color: var(--error);
    }
    &--published {
      border-color: var(--approved-30);
      background-color: var(--approved-30);
      color: var(--approved);
    }
  }

  @include for-big-desktop-up {
    &__header {
      p {
        max-width: rem(644);
      }
    }
  }
}
