@import 'sass-helpers.scss';

.app-modal {
  background: var(--white);
  border-radius: rem(9);
  position: relative;

  header {
    background: linear-gradient(90deg, var(--primary) -7.68%, white 112.68%);
    border-radius: rem(8) rem(8) 0 0;
    color: var(--white);
    font-family: $font-regular;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    min-height: rem(48);
    padding: rem(8) rem(24);
  }
  main {
    background: var(--white);
    border-radius: rem(9);
    font-size: rem(16);
    padding: rem(16);

    h3 {
      font-size: rem(18);
      font-family: $font-bold;
    }

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80%;
    }
  }
  footer {
    background: var(--white);
    border-radius: 0 0 rem(8) rem(8);
    box-shadow: 3px -5px 10px rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: center;
    padding: rem(8) rem(16);
    button {
      &:not(:last-child) {
        margin-right: rem(24);
      }
    }
  }

  .close {
    color: var(--white);
  }

  &__close {
    background-color: transparent;
    border: 0;
    color: var(--text-30);
    font-size: 24px;
    padding: rem(8);
    position: absolute;
    right: rem(16);
    top: 0;
  }

  @include for-big-desktop-up {
    main {
      padding: rem(36);
    }
  }
}
