:root {
  /** Landing Custom variables for customization **/
  --backgroundCoverColor: var(--white);
  --backgroundColor: var(--backgroundCoverColor);
  --strokeColor: var(--stroke);
  --fontPrimaryColor: var(--text);
  --fontSecondaryColor: var(--text-60);
  --actionFillColor: var(--primary);
  --actionFontColor: var(--white);
  --fontFamily: var(--fontFamily);
}
.oc-custom-styles {
  * {
    font-family: var(--fontFamily);
  }
  header {
    section {
      * {
        color: var(--fontSecondaryColor);
      }
    }
    h1 {
      color: var(--fontPrimaryColor);
    }
  }

  main {
    p {
      color: var(--fontSecondaryColor);
      span {
        color: var(--actionFillColor);
      }
    }
  }
  .app-btn--primary {
    background-color: var(--actionFillColor);
    border-color: var(--actionFillColor);
    color: var(--actionFontColor);

    &:hover:not([disabled]):not(.disabled) {
      background-color: var(--actionFillColor);
      border-color: var(--actionFillColor);
      color: var(--actionFontColor);
      opacity: 0.6;
    }
  }
  .app-btn--tertiary {
    color: var(--actionFillColor);

    &:hover:not([disabled]):not(.disabled) {
      color: var(--actionFillColor);
      opacity: 0.6;
    }
  }
  .app-btn--secondary {
    background-color: transparent;
    color: var(--actionFillColor);

    &:hover {
      &:not([disabled]):not(.disabled) {
        background-color: transparent;
        box-shadow: none;
        color: var(--actionFillColor);
        text-shadow: 0px 0px 0.5px var(--actionFillColor);
      }
    }
    &:not(.keep-border) {
      border-color: transparent;
      &:hover {
        &:not([disabled]):not(.disabled) {
          border-color: transparent;
        }
      }
    }
    &.keep-border {
      border-color: var(--actionFillColor);
      &:hover {
        &:not([disabled]):not(.disabled) {
          border-color: var(--actionFillColor);
        }
      }
    }
  }

  .app-btn--link {
    color: var(--actionFillColor);
  }
}
