@import "../../variables.scss";

svc-page-navigator-item,
.svc-page-navigator-item {
  display: block;
  width: calcSize(5.5);
  height: calcSize(4.5);
  min-height: calcSize(4.5);
  cursor: pointer;
  position: relative;
}

.svc-page-navigator-item-content {
  height: 100%;
  width: 100%;

  &:hover,
  &:focus {
    outline: none;
  }
}

.svc-page-navigator__items--up {
  .svc-page-navigator-item-content {
    transition: 0.25s ease-in-out;
    transform: translateY(-100%);
  }

  svc-page-navigator-item:first-child .svc-page-navigator-item-content {
    opacity: 0;
  }
}

.svc-page-navigator__items--down {
  .svc-page-navigator-item-content {
    transition: 0.25s ease-in-out;
    transform: translateY(100%);
  }

  svc-page-navigator-item:last-child .svc-page-navigator-item-content {
    opacity: 0;
  }
}

.svc-page-navigator-item__dot {
  box-sizing: content-box;
  position: absolute;
  border-radius: 50%;
  width: var(--ctr-page-navigator-item-dot-radius-small, 6px);
  height: var(--ctr-page-navigator-item-dot-radius-small, 6px);
  right: calc(50% - var(--ctr-page-navigator-item-dot-radius-small, 6px) / 2);
  top: calc(50% - var(--ctr-page-navigator-item-dot-radius-small, 6px) / 2);
  background: var(--ctr-page-navigator-item-dot-color-default, $border);
}

.svc-page-navigator-item__text {
  @include ctrSmallBoldFont;
  color: var(--ctr-page-navigator-item-text-color-hovered, #{$foreground});
}

.svc-page-navigator-item--selected {
  .svc-page-navigator-item__dot {
    width: calcSize(1);
    height: calcSize(1);
    right: calc(50% - 1.5 * #{$base-unit} / 2);
    top: calc(50% - 1.5 * #{$base-unit} / 2);
    background: var(--ctr-page-navigator-item-dot-color-selected, $background);
    border: calcSize(0.25) solid var(--ctr-page-navigator-item-dot-border-color-selected, $primary);
  }
}

.svc-page-navigator-item__banner {
  @include textEllipsis;

  right: calcSize(0.5);
  display: flex;
  align-items: center;
  animation: 0.5s ease-in;
  opacity: 0;
  max-width: 0;
  z-index: 20;
  position: absolute;
  top: 0;
  height: 100%;
  background-color: var(--ctr-page-navigator-item-background-color-hovered, $background);
  box-shadow: $shadow-medium;
  transition: opacity $creator-transition-duration;

  border-radius: var(--ctr-page-navigator-item-corner-radius, calcSize(12.5));
  padding: var(--ctr-page-navigator-item-padding-top, 0) var(--ctr-page-navigator-item-padding-right, 0)
    var(--ctr-page-navigator-item-padding-bottom, 0) var(--ctr-page-navigator-item-padding-left-hovered, 0);
  flex-direction: row;
  gap: var(--ctr-page-navigator-item-gap, 8px);

  .svc-page-navigator-item__dot {
    position: absolute;
    display: inline-block;
    top: calcSize(1.75);
    right: calcSize(1.75);
  }
}

.svc-creator__toolbox--right,
[dir='rtl'],
[style*='direction:rtl'],
[style*='direction: rtl'] {
  .svc-page-navigator-item__banner {
    right: unset;
    left: calc(0.625 * var(--base-unit, 8px));

    .svc-page-navigator-item__dot {
      right: unset;
      left: calcSize(1.75);
    }
  }
}

.svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover,
.svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus {
  .svc-page-navigator-item__banner {
    padding: 0 calcSize(4.5) 0 calcSize(2.5);
    max-width: calcSize(25);
    opacity: 1;
    background: var(--ctr-page-navigator-item-background-color-hovered, $background);
  }

  .svc-page-navigator-item__dot {
    border: none;
    width: var(--ctr-page-navigator-item-dot-radius-large, calcSize(1));
    height: var(--ctr-page-navigator-item-dot-radius-large, calcSize(1));
    background: var(--ctr-page-navigator-item-dot-color-hovered, $primary);
  }
}

.svc-creator__toolbox--right,
[dir='rtl'],
[style*='direction:rtl'],
[style*='direction: rtl'] {

  .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):hover,
  .svc-page-navigator-item-content:not(.svc-page-navigator-item--selected):focus {
    .svc-page-navigator-item__banner {
      padding: 0 calcSize(2.5) 0 calcSize(4.5);
    }
  }
}

.svc-page-navigator-item--disabled {
  .svc-page-navigator-item__banner {
    color: $foreground;
    opacity: 0.25;
  }
}