$input: 300;

.consent-campaign {
  display: flex;
  flex-direction: row;

  app-collapsible-element {
    display: block;
    padding: rem(16) 0 rem(32) 0;
    margin-bottom: rem(16);

    &:not(.no-border) {
      border-bottom: 2px solid var(--text-10);
    }

    .collapsible-element {
      border: none;
      margin-bottom: 0;
      padding-bottom: 0;

      span {
        &.consent-campaign__token-img {
          width: rem(35);
        }
      }

      &__toggle {
        background: transparent;
        border: none;
        padding: 0;
        fa-icon {
          color: var(--secondary-60);
        }
        span {
          color: var(--secondary-60);
          font-size: rem(24);
          font-weight: 700;
          margin-left: rem(24);

          button {
            position: absolute;
            right: rem(8);
            fa-icon {
              color: var(--primary);
            }
          }
        }
      }

      &__wrapper {
        padding: 0;

        &--expanded {
          background: transparent;
          border: none;

          .collapsible-element__toggle {
            margin-bottom: rem(32);
          }
        }
      }
    }
  }

  app-campaign-viewer {
    background-color: var(--backgroundCoverColor);
  }

  app-consent-policy-reward-manager {
    border-bottom: 2px solid var(--text-10);
    display: block;
    margin-bottom: rem(16);
  }

  app-consent app-consent-policy-reward-manager {
    width: 100%;
  }

  app-wygwys-editor {
    display: flex;
    flex-direction: column;
  }
  hr {
    border: 1px solid var(--text-10);
    margin-top: 0;
    opacity: 1;
    &.dashed {
      border: 1px dashed var(--text-10);
    }
  }
  h4 {
    color: var(--secondary-60);
    font-size: rem(18);
  }
  label {
    span {
      color: var(--text-30);
      margin-left: rem(4);
    }
  }
  .a-link {
    align-items: center;
    display: flex;
    font-weight: 400;
    padding: 0;
    width: fit-content;
    fa-icon {
      align-items: center;
      display: flex;
      margin-right: rem(12);
    }
  }
  .advanced-rules__add-rule {
    margin-left: auto;
  }
  .data-usage-image-remove {
    fa-icon {
      margin-left: rem(12);
    }
  }
  .expiration-time-remove {
    height: rem(48);
  }

  .consent-survey {
    padding-top: rem(16);
    &__survey-model-btn {
      width: rem(200);
    }
  }

  &__dataset-reward {
    position: relative;
    & > button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .dropdown {
    .nav-wrapper {
      button {
        width: auto;
      }
    }
  }

  .ql-toolbar,
  .ql-container {
    border: 2px solid var(--grey);
  }

  .ql-toolbar {
    border-top-left-radius: rem(8);
    border-top-right-radius: rem(8);
  }

  .ql-container {
    border-bottom-left-radius: rem(8);
    border-bottom-right-radius: rem(8);
  }

  &__maxContentExceeded {
    .ql-toolbar,
    .ql-container {
      border-color: var(--error-60);
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: rem(116);

    & > * {
      &:not(:first-child) {
        margin-left: rem(32);
      }
    }

    .dropdown {
      .dropdown-toggle {
        height: rem(40);
        &::after {
          margin-left: rem(8);
        }
      }
      .dropdown-item {
        border: none;
        padding-top: 0;
        padding-bottom: 0;
        &:hover {
          font-weight: normal;
          text-decoration: none;
        }
      }
      .dropdown-menu {
        width: rem(190);
      }

      button + button {
        margin-left: 0;
      }
    }

    &--owner-actions {
      align-items: center;
      margin-top: 0;
      margin-bottom: rem(42);
      justify-content: flex-start;
    }
  }
  &__datasets {
    hr {
      border: 1px dashed var(--text-10);
    }
    .app-btn--icon {
      height: rem(48);
    }
    .new-dataset {
      color: var(--primary);
    }
  }

  &__token-img {
    background-image: url('/assets/images/token.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    height: rem(48);
    margin-right: rem(8);
    width: rem(35);
    min-width: rem(35);
  }

  &__column {
    position: relative;
    &--form {
      padding-top: rem(20);
    }
  }
  &__refresh-datasets {
    background: transparent;
    border: none;
    color: var(--secondary-30);
    margin-right: rem(8);
  }
  &__rewards {
    .add-reward {
      margin-top: rem(-12);
      margin-left: auto;
      width: auto;
    }

    .reward {
      & > div {
        input {
          text-align: left;
        }
        &:first-child {
          width: rem(200);
        }
        &:last-child {
          flex: 1;
        }
      }
    }
  }
  &__row {
    display: flex;
    flex-direction: column;
    margin-left: rem(24);

    &--no-margin {
      margin-bottom: 0;
      .consent-broker-policy__field {
        margin-bottom: 0;
      }
    }

    &--no-wrap {
      flex-wrap: nowrap;
      flex-direction: row;

      .consent-broker-policy__field {
        &:not(:last-child) {
          margin-right: rem(8);
        }
      }
    }
  }

  &__save-btn {
    margin: rem(64) 0;
    justify-content: center;
  }
  &__field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: rem(32);
    max-width: 100%;
    position: relative;
    width: 100%;
    app-custom-select {
      & + div {
        margin-top: rem(16);
      }
      .dropdown {
        button {
          margin-bottom: 0 !important;
        }
      }
    }
    app-image-drop-zone {
      align-self: center;
      height: rem(125);
      width: rem(125);
    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    textarea {
      margin-bottom: 0;
    }

    textarea {
      height: rem(88);
      & + button,
      & + app-input-file,
      & + app-custom-checkbox {
        margin-top: rem(26);
      }
    }

    & + button {
      margin-left: rem(8);
    }

    &--large {
      width: 100%;
    }

    &--number {
      min-width: rem(70);
      width: rem(70);
      input {
        text-align: center;
      }
      img {
        height: rem(48);
      }
    }
    &--display-row {
      align-items: center;
      flex-direction: row;
    }

    &--campaignType {
      .custom-radio-list {
        display: flex;
        flex-direction: row;
        &__item {
          align-items: center;
          display: flex;
          flex: 1 0 auto;
          &:first-child {
            &::after {
              content: 'OR';
              display: inline-block;
              margin-left: rem(14);
              width: auto;
            }
          }
          &:not(:first-child) {
            margin-left: rem(16);
          }
        }
      }
    }

    &--rows {
      align-items: flex-end;
      flex-direction: row;
      justify-content: flex-start;
      & > div {
        margin-left: rem(16);
      }
      fa-icon {
        margin-right: rem(4);
      }
    }

    &--styles {
      label {
        white-space: nowrap;
      }
      width: rem(168);
    }
  }

  &__title {
    h1 {
      color: var(--secondary-60);
      font-size: rem(24);
      font-weight: 700;
      margin-bottom: rem(32);
    }
  }

  &__tip {
    color: var(--text-60);
    font-size: rem(12);
    &--floated {
      bottom: rem(-20);
      left: rem(4);
      position: absolute;
    }
  }

  &__image-fit-selection {
    .custom-radio-list {
      margin-top: rem(42);
      display: flex;
      &__item {
        button {
          border-radius: rem(8);
          color: var(--secondary-30, rgba(23, 26, 55, 0.3));
          border-color: var(--grey, rgba(23, 26, 55, 0.3));
          height: rem(24);
          padding: 0;
          text-transform: capitalize;
          width: rem(40);
        }
        &:first-child {
          button {
            border-right-width: 1px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        &:last-child {
          button {
            border-left-width: 1px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
        &--checked {
          button {
            border-color: var(--primary);
            background-color: var(--primary-10);
            color: var(--primary);
          }
        }
      }

      &--disabled {
        .custom-radio-list__item {
          button {
            &:hover {
              background-color: transparent;
              border-color: var(--grey, rgba(23, 26, 55, 0.3));
              box-shadow: none;
              color: var(--secondary-30, rgba(23, 26, 55, 0.3));
              cursor: default;
            }
          }
        }
      }
    }
  }

  &__days-before-expiration {
    margin-bottom: rem(32);
    position: relative;
    .error {
      color: var(--error-60);
      font-size: 80%;
      position: absolute;
      top: rem(50);
    }
  }

  @include for-tablet-portrait-up {
    app-campaign-viewer {
      @include elevation(elevation2);
      display: block;
      height: auto;
      width: rem(390);

      .campaign-viewer {
        overflow: auto;

        footer {
          flex-direction: column !important;
          button {
            pointer-events: none;
          }
        }
      }
    }

    &__column {
      width: rem(616);
      &--phone-preview {
        flex: 1;
        margin-left: rem(15);
        position: sticky;
        top: 0;

        & > {
          div {
            position: sticky;
            top: rem(32);
          }
        }

        form {
          display: inline-block;
          margin-top: rem(32);
          text-align: center;
          width: rem(390);
        }
        app-custom-switch {
          display: inline-block;
        }
      }
    }
    &__field {
      width: rem($input);

      &--large {
        width: 100%;
      }

      &--number {
        width: rem(70);
      }

      &--styles {
        width: rem(168);
      }
    }
  }

  @include for-big-desktop-up {
    &__actions {
      width: 100%;
    }

    &__field {
      app-image-drop-zone {
        align-self: flex-start;
      }

      &:not(:last-child) {
        margin-right: rem(16);
      }

      &--large {
        width: 100%;
      }

      &--styles {
        width: rem(168);
      }
    }
    &__row {
      flex-direction: row;
      flex-wrap: wrap;
      &--no-wrap {
        flex-wrap: nowrap;
      }
    }
  }

  @include for-desktop-large {
    &__column {
      &--form {
        width: rem(482);
      }
      &--phone-preview {
        margin-left: rem(90);
        img {
          margin-top: 0;
        }
      }
    }

    &__field {
      &--large {
        width: 100%;
      }
    }
  }
}
