@import "../../variables.scss";

svc-page-navigator,
.svc-page-navigator {
  display: flex;
  flex-direction: column;
}

.svc-page-navigator__navigator-icon {
  display: block;
  width: var(--ctr-page-navigator-button-icon-width, calcSize(3));
  height: var(--ctr-page-navigator-button-icon-height, calcSize(3));
  padding: var(--ctr-page-navigator-button-padding, calcSize(1.25));
  border-radius: var(--ctr-page-navigator-button-corner-radius, 50%);
  cursor: pointer;
  transition: background-color $creator-transition-duration;

  .sv-svg-icon {
    width: var(--ctr-page-navigator-button-icon-width, calcSize(3));
    height: var(--ctr-page-navigator-button-icon-height, calcSize(3));
  }

  use {
    fill: var(--ctr-page-navigator-button-icon-color, $foreground-light);
    transition: fill $creator-transition-duration;
  }
}

.svc-page-navigator__selector {
  width: calcSize(5.5);
  height: calcSize(5.5);
  min-height: calcSize(5.5);
}

survey-creator,
.svc-creator {
  .svc-page-navigator__selector {
    padding: 0;
    border: none;
    background-color: transparent;
    &:hover,
    &:focus {
      outline: none;

      .svc-page-navigator__navigator-icon {
        background-color: var(--ctr-page-navigator-button-background-color-hovered, $primary-light);

        use {
          fill: var(--ctr-page-navigator-button-icon-color-hovered, $primary);
        }
      }
    }
  }
}

.svc-page-navigator__selector--opened {
  .svc-page-navigator__navigator-icon {
    background-color: $primary-light;
    opacity: var(--ctr-page-navigator-button-opacity-pressed, 0.5);

    use {
      fill: var(--ctr-page-navigator-button-icon-color-hovered, $primary);
    }
  }
}

.svc-page-navigator__popup {
  min-width: calcSize(12.5);
}

.svc-tab-designer--with-page-navigator {
  .svc-tab-designer_content {
    margin-right: calcSize(6.5);
    width: calc(100% - calcSize(6.5));
  }
}

.svc-tab-designer__page-navigator {
  position: absolute;
  padding: calcSize(16.5) 0;
  top: 0;
  bottom: 0;
  right: calcSize(1.5);
}

.svc-page-navigator {
  padding: calcSize(1.5);
  padding-right: 0;
  width: calcSize(5.5);
}

.svc-creator__toolbox--right,
[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .svc-tab-designer--with-page-navigator {
    .svc-tab-designer_content {
      margin-right: 0;
      margin-left: calcSize(6.5);
    }
  }

  .svc-tab-designer__page-navigator {
    right: unset;
    left: calcSize(1.5);
  }

  .svc-page-navigator {
    padding: calcSize(1.5);
    padding-left: 0;
  }
}

.svc-tab-designer--bypage-mode {
  .svc-tab-designer_content {
    display: flex;
    flex-direction: row;
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  .svc-tab-designer__page-navigator {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    .svc-page-navigator {
      padding-right: 0;
    }
  }
}