.campaign-request {
  @include surveyRequest;
  display: block;
  margin: 0 auto;

  .oc-styles-wrapper {
    background-color: var(--backgroundCoverColor);
    display: block;
    height: 100%;
    overflow: auto;
    position: fixed;
    width: 100%;
  }

  &--data-request-center-vertical {
    height: 100vh;
    display: grid;
    place-items: center;
  }

  @include for-tablet-portrait-up {
    .oc-styles-wrapper {
      height: auto;
      max-height: 100%;
      position: relative;
    }
    &--center-vertical {
      height: 100vh;
      display: grid;
      place-items: center;
    }

    &--data-request-center-vertical {
      height: 100vh;
      display: grid;
      place-items: center;
    }
  }
}
