* {
  font-family: $font-flex;
}
// Headers styles.
h1 {
  color: var(--text);
  font-family: $font-flex;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
}

h2 {
  font-family: $font-flex;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}

h3 {
  font-family: $font-flex;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
}

h4 {
  font-family: $font-flex;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

h5 {
  font-family: $font-flex;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
}

h6 {
  font-family: $font-flex;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
}

@include for-big-desktop-up {
  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  h2 {
    font-size: 32px;
    line-height: 48px;
  }

  h3 {
    font-size: 20px;
    line-height: 48px;
  }

  h4 {
    font-size: 16px;
    line-height: 28px;
  }

  h5 {
    font-size: 12px;
    line-height: 24px;
  }

  h6 {
    font-size: 10px;
    line-height: 16px;
  }
}
