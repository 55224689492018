// Buttons
button {
  &.blank-btn {
    background: transparent;
    border: none;
  }
}
.app-btn {
  @include typography(field-text);
  border: 2px solid;
  border-radius: 40px;
  color: var(--white);
  display: inline-flex;
  align-items: center;
  height: rem(40);
  justify-content: center;
  padding: 0 rem(24);
  text-decoration: none;
  transition: 0.1s;
  white-space: pre;

  &:hover {
    &:not([disabled]):not(.disabled) {
      background-color: var(--primary);
      border-color: var(--primary);
      text-decoration: none;
    }
  }

  &--primary {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--white);
    &:hover {
      &:not([disabled]):not(.disabled) {
        background-color: var(--primary-60);
        border-color: var(--primary-10);
        color: var(--white);
      }
    }
  }

  &--secondary {
    background-color: var(--white);
    border-color: var(--primary);
    color: var(--primary);
    &:hover {
      background-color: var(--white);
      &:not([disabled]):not(.disabled) {
        @include elevation(elevation3);
        border-color: var(--primary-60);
        background-color: var(--white);
        color: var(--primary-60);
        font-weight: normal;
      }
    }
  }

  &--tertiary {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    color: var(--primary);
    &:hover {
      background-color: transparent;
      border-color: transparent;
      &:not([disabled]):not(.disabled) {
        text-shadow: 0px 5px 10px rgba(23, 26, 55, 0.08), 0px 5px 20px rgba(23, 26, 55, 0.1);
        color: var(--primary-60);
        background-color: transparent;
        border-color: transparent;
      }
    }
  }

  &--link {
    background: transparent;
    border: none;
    color: var(--primary);
    padding: 0;
    text-align: left;
    text-decoration: underline;
    &:hover {
      background-color: transparent;
      &:not([disabled]):not(.disabled) {
        background-color: transparent;
      }
    }
  }

  &--icon {
    background: transparent;
    border: none;
    color: var(--primary);
    padding: rem(8);
    &:hover {
      background-color: transparent;
      &:not([disabled]):not(.disabled) {
        background-color: transparent;
        color: var(--primary-60);
      }
    }
  }

  &[disabled] {
    cursor: default;
    opacity: 0.5;
  }
}

.app-link {
  background: transparent;
  border: none;
  &:focus {
    outline: 0;
  }
}

.app-icon-btn {
  background-color: transparent;
  border: none;
  color: var(--primary);
  &:hover {
    background-color: transparent;
    &:not([disabled]),
    &:not(.disabled) {
      color: var(--primary-60);
    }
  }

  &--trash {
    color: var(--cancel-button);
    &:hover {
      &:not([disabled]):not(.disabled) {
        color: var(--cancel-button-60);
      }
    }
  }
}

.grid-btn {
  background: transparent;
  border: none;
  text-decoration: underline;
}
