@import 'sass-helpers.scss';

form {
  &.disabled {
    & > * {
      pointer-events: none;
    }
  }
}

label {
  @include typography(field-title-sm);
  color: var(--secondary-60);
  margin-bottom: rem(16);
}

input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'],
select,
textarea {
  &:not(.sd-input, .sd-dropdown__filter-string-input) {
    @include formElement;

    &.form-control {
      @include formElement;
    }
    &:focus {
      border-color: var(--primary-60);
      outline: none;
    }
    &::placeholder {
      opacity: 0.8;
    }

    &.no-margin {
      &--bottom {
        margin-bottom: 0;
      }
    }
  }
}

textarea {
  height: rem(120);
}

input[type='text'],
textarea {
  &:not(.sd-input, .sd-dropdown__filter-string-input) {
    &:read-only {
      background-color: var(--grey-30);
    }
  }
}

:disabled {
  opacity: 0.5 !important;
  cursor: default !important;
}

.field-error {
  color: var(--error-60);
  font-size: rem(14);
  font-style: italic;
  &--error {
    color: var(--error);
  }
}

.input-group {
  .input-group-append {
    border-radius: rem(8);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    .btn-outline-secondary {
      border-color: var(--grey);
      border-width: 2px;
      border-left-width: 0;
      border-top-right-radius: rem(8);
      border-bottom-right-radius: rem(8);
    }
  }
  input {
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.label-checkbox {
  @include formElement;
  border: none;
  box-shadow: none;
  background: transparent;
  margin: 0;
}

span {
  &.field-rule {
    @include typography(under-field-text);
    color: var(--form-text);
    position: absolute;
    right: 0;
    bottom: rem(-24);
  }
}

.form-control {
  &:focus {
    box-shadow: none;
  }
}

@include for-tablet-portrait-up {
  label {
    margin-bottom: rem(8);
  }

  .label-checkbox {
    margin: 0;
  }
}

@include for-desktop-up {
  label {
    @include typography(field-title);
  }
}
