.graph__sidebar-text {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-bottom: 8px;
  &:hover {
    color: #0081ff;
    cursor: pointer;
    text-decoration: underline;
  }
}

.graph__sidebar-icon {
  width: 32px;
  height: 32px;
  background-image: url(/assets/images/file-alt-solid.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
}
