/*
* Bootstrap overrides https://getbootstrap.com/docs/4.0/getting-started/theming/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/
$input-focus-border-color: var(--primary-60);
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

// Options:
// Quickly modify global styling by enabling or disabling optional features.
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-hover-media-query: false;
$enable-grid-classes: true;
$enable-print-styles: true;

// Components:
// Define common padding and border radius sizes and more.

$border-radius: 0.15rem;
$border-radius-lg: 0.125rem;
$border-radius-sm: 0.1rem;

// Body:
// Settings for the `<body>` element.

$body-bg: #e4e5e6;

// Typography:
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 1rem;

$dropdown-link-hover-color: white;
$dropdown-link-hover-bg: #92009f;

$grid-breakpoints: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

$container-max-widths: (
  xxxs: 0,
  xxs: 320px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1600px,
);

$prefix: 'oc-';
$accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-button-color: var(--primary);
$accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
$accordion-icon-width: 1.25rem;
$accordion-icon-color: var(--primary);
$accordion-icon-active-color: var(--primary);
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);
$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$primary}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$primary}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$tooltip-bg: white;
