.dropdown-menu {
  border: 2px solid var(--grey);
  border-radius: rem(8);
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.25);
  padding: 0;

  /**
  when using container=body option, the dropdown is not included inside the dropdown but appended on the body
  */
  label {
    &.dropdown-item {
      margin-bottom: 0;
    }
  }

  .app-search-bar {
    height: rem(32);
    margin: rem(8) rem(16);

    input {
      height: 100%;
    }
  }

  .dropdown-item {
    &--no-hover {
      &:not(.item-disabled) {
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }
}

.dropdown-item {
  align-items: center;
  display: flex;
  height: rem(48);
  padding: rem(4) rem(16);

  &:not(.item-disabled),
  &:not(.disabled) {
    &:hover,
    &:focus {
      background-color: var(--primary-05);
      color: var(--primary);
    }
  }
  &.item-disabled,
  &.disabled {
    opacity: 0.6;
    cursor: default;
    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--text-60);
    }
  }
  &--no-hover {
    &:hover {
      background-color: transparent;
    }
  }
}

.dropdown,
.dropup {
  ul {
    padding: 0;
    margin: 0;
  }
  button {
    @include formBasicElement;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      border: none;
      box-shadow: none;
      height: calc(100% - #{rem(2)});
      padding-left: 0;
      margin-bottom: 0;
    }

    span,
    input {
      line-height: normal;
      overflow: hidden;
      padding: 0;
      max-width: 90%;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.ng-invalid {
      &.ng-touched {
        border: 2px solid var(--error-60);
      }
    }
  }

  .dropdown-toggle {
    &::after {
      margin-left: auto;
    }
  }

  &.show {
    button {
      border: 2px solid var(--primary-60);
    }
  }
}

.v2-dropdown {
  button,
  .dropdown-item {
    color: var(--text-60);
    background-color: transparent;
    border: none;
    &:hover {
      font-weight: normal;
    }
  }
  .dropdown-toggle {
    padding: 0;
    &::after {
      margin-left: rem(16);
    }
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: var(--text-60);
    }
  }

  .dropdown-menu {
    @include elevation(elevation3);
    width: rem(200);
    button {
      padding: 0 rem(16);
      transition: 0.2s;
      &:hover {
        color: var(--text-60);
      }
    }
  }

  &.show {
    button {
      border: none;
    }
  }
}
