.custom-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(.custom-checkbox--disabled):not(:disabled) {
    cursor: pointer;
    &:hover {
      .custom-checkbox__box {
        border-color: var(--primary);
      }
    }
  }
  &__box {
    border: 2px solid;
    border-color: var(--text-30);
    border-radius: 2px;
    display: flex;
    flex: 0 0 rem(17);
    height: rem(17);
    width: rem(17);
    &--checked {
      border-color: var(--primary);
      span {
        background-color: var(--primary);
        display: block;
        height: 100%;
        mask-image: url('/assets/images/check-mark.svg');
        mask-size: cover;
        width: 100%;
      }
    }
  }
  &__label {
    color: var(--text-60);
    margin-left: rem(9);
    margin-top: 2px;
  }
  &--invalid {
    &.custom-checkbox--touched {
      .custom-checkbox__box {
        border-color: var(--error-60);
      }
    }
  }
  &--disabled {
    opacity: 0.5;
  }
}

label {
  input[type='checkbox'] {
    &.custom-styles {
      margin-right: rem(9);
    }
  }
}

input[type='checkbox'] {
  &.custom-styles {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;

    font: inherit;

    border: 2px solid;
    border-color: var(--text-30);
    border-radius: 2px;
    cursor: pointer;
    display: grid;
    height: rem(17);
    place-content: center;
    transform: translateY(-0.075em);
    width: rem(17);
  }

  &::before {
    content: '';
    box-shadow: inset 1em 1em var(--primary);
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    height: 0.65em;
    transform: scale(0);
    width: 0.65em;
  }

  &:checked,
  &:hover {
    border-color: var(--primary);
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
