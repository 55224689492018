@import 'sass-helpers.scss';
.app__icon {
  height: rem(24);
  padding: 0;
  position: relative;
  width: rem(24);
  &:before {
    display: block;
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

  &--bitcoin {
    &:before {
      background-image: url('/assets/images/icons/icon-bitcoin.svg');
    }
  }

  &--dollar {
    &:before {
      background-image: url('/assets/images/icons/icon-dollar.svg');
    }
  }

  &--download {
    &:before {
      background-image: url('/assets/images/icons/icon-download.svg');
    }
  }

  &--eye {
    &:before {
      background-image: url('/assets/images/icons/icon-eye.svg');
    }
  }

  &--nfc {
    &:before {
      background-image: url('/assets/images/icons/icon-nfc.svg');
    }
  }

  &--trash {
    &:before {
      background-image: url('/assets/images/icons/icon-trash.svg');
    }
  }
  &--calendar {
    &:before {
      background-image: url('/assets/images/icons/icon-calendar.svg');
    }
  }
  &--edit {
    &:before {
      background-image: url('/assets/images/icons/icon-edit.svg');
    }
  }
  &--upload {
    &:before {
      background-image: url('/assets/images/icons/icon-upload.svg');
    }
  }
}
